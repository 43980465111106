import React from "react"
import { navigate } from "gatsby-link"
import Layout from "../components/layout"
import SEO from "../components/seo"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Contact() {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  const googleMapUrl =
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12043.818031457326!2d29.133885!3d41.0043689!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x603d835f1410d309!2sCarrara%20Pilates%26Cafe!5e0!3m2!1str!2str!4v1605562697188!5m2!1str!2str"

  return (
    <Layout>
      <SEO title="İletişim" />
      <h1>Iletisim</h1>
      <section className="section pt-5 mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="map">
                <iframe
                  title="carraraMap"
                  src={googleMapUrl}
                  style={{ border: 0 }}
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
              <div className="custom-form p-4 rounded shadow">
                <div id="message" />
                <form
                  netlify
                  name="contact"
                  method="post"
                  action="/tesekkurler/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group position-relative">
                        <label>
                          Adınız ve Soyadınız{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <i className="mdi mdi-account ml-3 icons" />
                        <input
                          required
                          name="name"
                          id="name"
                          type="text"
                          className="form-control pl-5"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-6">
                      <div className="form-group position-relative">
                        <label>
                          E-Posta Adresiniz
                          <span className="text-danger">*</span>
                        </label>
                        <i className="mdi mdi-email ml-3 icons" />
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control pl-5"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {/*end col*/}
                    <div className="col-lg-12">
                      <div className="form-group position-relative">
                        <label>Telefon</label>
                        <i className="mdi mdi-comment-text-outline " />
                        <input
                          name="phone"
                          id="phone"
                          className="form-control"
                          placeholder=""
                          defaultValue={""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group position-relative">
                        <label>Mesajınız</label>
                        <i className="mdi mdi-comment-text-outline" />
                        <textarea
                          name="comments"
                          id="comments"
                          rows={4}
                          className="form-control"
                          placeholder=""
                          defaultValue={""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  {/*end row*/}
                  <div className="row">
                    <div className="col-sm-12 text-center">
                      <input
                        type="submit"
                        name="send"
                        className="submitBnt btn btn-primary btn-block"
                        value="Gönder"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 order-1 order-md-2">
              <div className="title-heading ml-lg-4">
                <h4 className="mb-4">İletişim Detayları</h4>
                <p className="text-muted">
                  <span className="text-primary font-weight-bold">
                    Carrara Pilates
                  </span>{" "}
                  ile sağlıklı bir başlangıç yapın. Ücretsiz deneme dersi almak
                  için bize ulaşın.
                </p>
                <div className="contact-detail mt-3">
                  <div className="icon mt-3 float-left">
                    <i className="mdi mdi-email-variant text-muted mdi-36px mr-3" />
                  </div>
                  <div className="content mt-3 overflow-hidden d-block">
                    <h4 className="title font-weight-bold mb-0">E-Posta</h4>
                    <a
                      href="mailto:contact@example.com"
                      className="text-primary h6"
                    >
                      info@carrarapilates.com
                    </a>
                  </div>
                </div>
                <div className="contact-detail mt-3">
                  <div className="icon mt-3 float-left">
                    <i className="mdi mdi-phone text-muted mdi-36px mr-3" />
                  </div>
                  <div className="content mt-3 overflow-hidden d-block">
                    <h4 className="title font-weight-bold mb-0">Telefon</h4>
                    <a href="tel:05427476657" className="text-primary h6">
                      0542 747 66 57
                    </a>
                    <br />
                    <a href="tel:02163646664" className="text-primary h6">
                      0216 364 66 64
                    </a>
                  </div>
                </div>
                <div className="contact-detail mt-3">
                  <div className="icon mt-3 float-left">
                    <i className="mdi mdi-map-marker-outline text-muted mdi-36px mr-3" />
                  </div>
                  <div className="content mt-3 overflow-hidden d-block">
                    <h4 className="title font-weight-bold mb-0">Ulaşım</h4>
                    <p>
                      Tatlısu Mahallesi, Akdağ Caddesi, No: 31/A
                      Ümraniye/İstanbul, 34764 Ümraniye/İstanbul, Türkiye
                    </p>
                    <a
                      href='https://g.page/carrara-pilates-cafe?share'
                      className="video-play-icon h6 text-primary"
                    >
                      Yol Tarifi Al!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
